import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import PropTypes from "prop-types";
import "./hero.css";

import { fetchHeroContent } from "../../features/hero/heroTypeActions";
import { ApplicationState } from "../../redux/initialState";
import { HeroType } from "../../features/hero/heroTypes";

import { GelHeading1, GelHeading5, GelBlockQuote, GelRowLayout, GelScreenDetectorContext } from "@tal-gel/components";

interface PropsFromState {
  loading: boolean;
  data: HeroType;
  errors?: string;
}

interface propsFromDispatch {
  fetchHeroContent: () => any;
}

type AllProps = PropsFromState & propsFromDispatch;

function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0;
}

const HeroContent: React.FC<AllProps> = ({ loading, errors, data, fetchHeroContent }) => {
  useEffect(() => {
    if (isEmptyObject(data)) {
      fetchHeroContent();
    }
  }, [data, fetchHeroContent]);

  const { screen, isXlScreen, isXsScreen } = useContext(GelScreenDetectorContext);

  return (
    <div
      className="hero-image"
      style={{
        width: isXlScreen(screen) ? 1200 : isXsScreen(screen.width) ? "100%" : "100vw",
        minHeight: isXsScreen(screen) ? "200px" : "400px",
        height: "auto",
        backgroundSize: "cover",
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${data.heroImage})`,
      }}
    >
      <GelRowLayout alignment={"center"}>
        {isXlScreen(screen) ? (
          <GelHeading1 style={{ color: "#fff" }}>{data.heroTitle}</GelHeading1>
        ) : (
          <GelHeading5 style={{ color: "#fff" }}>{data.heroTitle}</GelHeading5>
        )}
        <GelBlockQuote style={{ color: "#fff", textAlign: "center", fontSize: isXlScreen(screen) ? "24px" : "18px" }}>
          {data.heroDescription}
        </GelBlockQuote>
      </GelRowLayout>
    </div>
  );
};

const mapStateToProps = ({ heroType }: ApplicationState) => ({
  loading: heroType.loading,
  errors: heroType.errors,
  data: heroType.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchHeroContent: () => {
      dispatch(fetchHeroContent());
    },
  };
};

HeroContent.propTypes = {
  loading: PropTypes.any,
  errors: PropTypes.string,
  data: PropTypes.any.isRequired,
  fetchHeroContent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroContent);
